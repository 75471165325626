<template>
  <div class="item-box">
    <div class="img-box">
      <img :src=cardData.thumbnail>
    </div>
    <div class="text-box">
      <div class="type">{{ cardData.subCategory }}</div>
      <div class="item-title">
        <p>{{ cardData.title }}</p>
      </div>
      <div v-if="!isAll" class="price">
        <img src="@/assets/images/icon/icon_recipt.png" alt="">
        {{ cardData.price.toLocaleString() }}원
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "CardView",
  props: {
    cardData: Object,
    isAll: Boolean
  }
}
</script>

<style scoped>

.item-box {
  cursor: pointer;
}

.item-box:last-child {
  margin: 0;
}

.item-box .img-box {
  display: flex;
  height: 163px;
  border-radius: 20px;
  border: 1px solid #E4E4E7;
  background: #FFF;
  margin-bottom: 16px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.item-box .img-box img {
  width: 100%;
  height: 100%;
}

.item-box .text-box {
  padding: 0 16px;
  text-align: start;
}

.item-box .text-box .type {
  display: inline;
  padding: 3px 6px;
  border-radius: 6px;
  opacity: 0.65;
  background: #E4E4E7;
  color: #52525B;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.28px;
}

.item-box .text-box .item-title {
  height: 46px;
}

.item-box .text-box .item-title p {
  margin-top: 6px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.32px;
  word-break: break-all;
  text-align: start;
}

.item-box .text-box .price {
  margin-top: 6px;
  text-align: right;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.32px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.item-box .text-box .price img {
  width: 18px;
  height: 18px;
  margin-right: 6px;
}

</style>