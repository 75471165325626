<template>
  <div>
    <div class="main-frame">
      <div class="items-container">
        <div class="category">
          <div class="title-box">
            <div class="icon-box">
              <img src="@/assets/images/icon/icon_folder.png">
            </div>
            <h3 class="category-title">플러그인/라이브러리</h3>
            <p class="hot">HOT</p>
          </div>
          <div class="tag-container">
            <div class="keyword">
              <div class="tag">안드로이드 스튜디오</div>
              <div class="tag">엑스코드</div>
              <div class="tag">아두이노</div>
            </div>
            <div class="more" @click="pageMove('plugin')">더보기</div>
          </div>
        </div>
        <div class="card-line">
          <div class="card-box" v-for="(plugin,i) in itemList.plugin" :key="i"
               @click="moveItemPage(plugin)">
            <CardView :isAll="true" :cardData="plugin"/>
          </div>
        </div>
      </div>
      <div class="items-container">
        <div class="category">
          <div class="title-box">
            <div class="icon-box">
              <img src="@/assets/images/icon/icon_category.png">
            </div>
            <h3 class="category-title">프로그램 패키지</h3>
          </div>
          <div class="tag-container">
            <div class="keyword">
              <div class="tag">안드로이드 스튜디오</div>
            </div>
            <div class="more" @click="pageMove('program')">더보기</div>
          </div>
        </div>
        <div class="card-line">
          <div class="card-box" v-for="(program,i) in itemList.program" :key="i"
               @click="moveItemPage(program)">
            <CardView :isAll="true" :cardData="program"/>
          </div>
        </div>
      </div>
      <div class="items-container">
        <div class="category">
          <div class="title-box">
            <div class="icon-box">
              <img src="@/assets/images/icon/icon_edit.png">
            </div>
            <h3 class="category-title">디자인</h3>
          </div>
          <div class="tag-container">
            <div class="keyword">
              <div class="tag">3D Modeling</div>
              <div class="tag">Figma</div>
            </div>
            <div class="more" @click="pageMove('design')">더보기</div>
          </div>
        </div>
        <div class="card-line">
          <div class="card-box" v-for="(design,i) in itemList.design" :key="i"
               @click="moveItemPage(design)">
            <CardView :isAll="true" :cardData="design"/>
          </div>
        </div>
      </div>
    </div>
    <div class="banner">
      <div class="banner-frame">
        <h2>라마켓에서 리소스를 공유하고<br/>
          또다른 수익을 창출 해보세요!</h2>
        <div class="banner-img">
          <img src="@/assets/images/img_theme_banner.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import CardView from "@/components/CardView.vue";
import {firestore} from "@/firebase/firebaseConfig";

export default {
  name: 'Home',
  components: {
    CardView
  },
  data() {
    return {
      itemList: {
        plugin: [],
        program: [],
        design: [],
      }
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      const self = this;
      self.itemList.plugin.splice(0)
      self.itemList.program.splice(0)
      self.itemList.design.splice(0)
      firestore.collection('item')
          .orderBy('lastDate', 'desc')
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              const _data = doc.data()
              _data['uid'] = doc.id

              switch (_data.category) {
                case 'plugin':
                  if (self.itemList.plugin.length === 4) return
                  self.itemList.plugin.push(_data);
                  break;
                case 'program':
                  if (self.itemList.program.length === 4) return
                  self.itemList.program.push(_data);
                  break;
                case 'design':
                  if (self.itemList.design.length === 4) return
                  self.itemList.design.push(_data);
                  break;
              }
            })
          })
    },
    pageMove(index) {
      const self = this;
      self.$router.push({name: 'Category', params: {type: index}})
    },
    moveItemPage(value) {
      const self = this;
      self.$router.push({name: 'ItemDetail', params: {type: value.category, uid: value.uid}})
    },
  },
}
</script>
<style scoped>

.main-frame {
  width: 1224px;
  margin: 0 auto;
  padding: 60px 16px;
}

.main-frame .items-container {
  margin-bottom: 60px;
}

.main-frame .items-container:last-child {
  margin: 0;
}

.main-frame .category .tag-container,
.main-frame .category .title-box,
.main-frame .category {
  display: flex;
  align-items: center;
}

.main-frame .category {
  justify-content: space-between;
  margin-bottom: 16px;
}

.category .icon-box {
  display: flex;
  width: 52px;
  height: 52px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background: #FBF4EB;
  margin-right: 16px;
}

.category .icon-box img {
  width: 24px;
  height: 24px;
}

.category .category-title {
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.4px;
}

.category .hot {
  color: #FF8A00;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 800;
  line-height: normal;
  letter-spacing: -0.24px;
  margin: 0 20px 0 6px;
}

.category .keyword {
  display: flex;
  padding: 0 16px;
}

.category .keyword .tag {
  height: 32px;
  border-radius: 20px;
  background: #ffffff;
  border: 1px solid #D4D4D8;
  padding: 6px 10px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.28px;
  margin-left: 10px;
  cursor: pointer;
}

.category .more {
  color: #A1A1AA;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.32px;
  text-align: end;
  margin-left: 20px;
  cursor: pointer;
}

.items-container .card-line {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 20px;
}

.banner {
  height: 286px;
  background: #FBF4EB;
}

.banner .banner-frame {
  width: 1224px;
  height: 100%;
  margin: 0 auto;
  padding: 0 130px;
  display: flex;
  justify-content: space-between;
  align-items: end;
}

.banner .banner-frame h2 {
  font-size: 30px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.6px;
  margin: auto 0;
}

.banner .banner-frame .banner-img {
  width: 420px;
  height: 270px;
}

.banner .banner-frame .banner-img img {
  width: 100%;
  height: 100%;
}
</style>