var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"main-frame"},[_c('div',{staticClass:"items-container"},[_c('div',{staticClass:"category"},[_vm._m(0),_c('div',{staticClass:"tag-container"},[_vm._m(1),_c('div',{staticClass:"more",on:{"click":function($event){return _vm.pageMove('plugin')}}},[_vm._v("더보기")])])]),_c('div',{staticClass:"card-line"},_vm._l((_vm.itemList.plugin),function(plugin,i){return _c('div',{key:i,staticClass:"card-box",on:{"click":function($event){return _vm.moveItemPage(plugin)}}},[_c('CardView',{attrs:{"isAll":true,"cardData":plugin}})],1)}),0)]),_c('div',{staticClass:"items-container"},[_c('div',{staticClass:"category"},[_vm._m(2),_c('div',{staticClass:"tag-container"},[_vm._m(3),_c('div',{staticClass:"more",on:{"click":function($event){return _vm.pageMove('program')}}},[_vm._v("더보기")])])]),_c('div',{staticClass:"card-line"},_vm._l((_vm.itemList.program),function(program,i){return _c('div',{key:i,staticClass:"card-box",on:{"click":function($event){return _vm.moveItemPage(program)}}},[_c('CardView',{attrs:{"isAll":true,"cardData":program}})],1)}),0)]),_c('div',{staticClass:"items-container"},[_c('div',{staticClass:"category"},[_vm._m(4),_c('div',{staticClass:"tag-container"},[_vm._m(5),_c('div',{staticClass:"more",on:{"click":function($event){return _vm.pageMove('design')}}},[_vm._v("더보기")])])]),_c('div',{staticClass:"card-line"},_vm._l((_vm.itemList.design),function(design,i){return _c('div',{key:i,staticClass:"card-box",on:{"click":function($event){return _vm.moveItemPage(design)}}},[_c('CardView',{attrs:{"isAll":true,"cardData":design}})],1)}),0)])]),_vm._m(6)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title-box"},[_c('div',{staticClass:"icon-box"},[_c('img',{attrs:{"src":require("@/assets/images/icon/icon_folder.png")}})]),_c('h3',{staticClass:"category-title"},[_vm._v("플러그인/라이브러리")]),_c('p',{staticClass:"hot"},[_vm._v("HOT")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"keyword"},[_c('div',{staticClass:"tag"},[_vm._v("안드로이드 스튜디오")]),_c('div',{staticClass:"tag"},[_vm._v("엑스코드")]),_c('div',{staticClass:"tag"},[_vm._v("아두이노")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title-box"},[_c('div',{staticClass:"icon-box"},[_c('img',{attrs:{"src":require("@/assets/images/icon/icon_category.png")}})]),_c('h3',{staticClass:"category-title"},[_vm._v("프로그램 패키지")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"keyword"},[_c('div',{staticClass:"tag"},[_vm._v("안드로이드 스튜디오")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title-box"},[_c('div',{staticClass:"icon-box"},[_c('img',{attrs:{"src":require("@/assets/images/icon/icon_edit.png")}})]),_c('h3',{staticClass:"category-title"},[_vm._v("디자인")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"keyword"},[_c('div',{staticClass:"tag"},[_vm._v("3D Modeling")]),_c('div',{staticClass:"tag"},[_vm._v("Figma")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner"},[_c('div',{staticClass:"banner-frame"},[_c('h2',[_vm._v("라마켓에서 리소스를 공유하고"),_c('br'),_vm._v(" 또다른 수익을 창출 해보세요!")]),_c('div',{staticClass:"banner-img"},[_c('img',{attrs:{"src":require("@/assets/images/img_theme_banner.png"),"alt":""}})])])])
}]

export { render, staticRenderFns }